import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import classNames from 'classnames'
import LogisticsHeader from '../images/logistics_header.jpg'
import * as styles from './Logistics.module.scss'
import SectionHeading from '../components/SectionHeading/SectionHeading'
import Card from '../components/Card/Card'
import { CHANGE_HEADER_IMAGE } from '../store/actionTypes'
import { Context } from '../store/Provider'
import Header from '../components/Header/Header'
import Seo from '../components/Seo/Seo'

interface Props {
	location: Location
}

export default ({ location }: Props) => {
	const { dispatch } = useContext(Context)

	useEffect(() => {
		dispatch({
			type: CHANGE_HEADER_IMAGE,
			payload: {
				image: LogisticsHeader,
				title: 'Logistika',
				viewHeight: 50,
				parallax: false,
				isButtonDownEnabled: false
			}
		})
	}, [dispatch])

	return (
		<div>
			<Seo title='Logistika' pathName={location.pathname} />
			<Header
				image={LogisticsHeader}
				title='Logistika'
				viewHeight={50}
				parallax={false}
				isButtonDownEnabled={false}
			/>
			<div className={styles.containerGrey}>
				<Container className={classNames(styles.containerServices, 'pt-5')}>
					<SectionHeading title='Tentai ir cisternos' />
					<Row className='pt-5 pb-3 text-center'>
						<Col>
							<p className={styles.cardsDescription}>
								Mes užtikrinsime jūsų krovinių saugumą ir pristatymą laiku
							</p>
						</Col>
					</Row>
					<Row className='pt-2'>
						<Col md={6} sm={12} xs={12}>
							<Card className='mb-5' title='NVS šalyse'>
								<ul className={styles.list}>
									<li>
										Krovinių gabenimas iš Europos, Skandinavijos ir Baltijos šalių į NVS šalis
									</li>
									<li>Krovinių gabenimas iš NVS šalių į Europą, Skandinaviją ir Baltijos šalis</li>
									<li>Mes galime Jums pasiūlyti muitinės sandelius</li>
									<li>Muitinės tarpininko paslaugas</li>
									<li>Vairuotojus ir vadybininkus, kurie turi patirtį dirbant su NVS šalimis</li>
								</ul>
							</Card>
						</Col>
						<Col md={6} sm={12} xs={12}>
							<Card className='mb-5' title='Baltijos šalyse'>
								<ul className={styles.list}>
									<li>Krovinių gabenimas tarp Baltijos šalių</li>
									<li>Krovinių gabenimas Baltijos šalių viduje</li>
									<li>Krovinių gabenimas iš Baltijos šalių į Skandinaviją, Europą ir NVS šalis</li>
								</ul>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	)
}
